export enum Subtask {
  COMMUNICATION_AND_COORDINATION = "COMMUNICATION_AND_COORDINATION",
  FULLSTACK_DEVELOPMENT = "FULLSTACK_DEVELOPMENT",
  PLANNING_AND_ESTIMATION = "PLANNING_AND_ESTIMATION",
  PRODUCT_BACKLOG = "PRODUCT_BACKLOG",
  REQUIREMENTS_ENGINEER = "REQUIREMENTS_ENGINEER",
  REVIEW_MEETING_MODERATION = "REVIEW_MEETING_MODERATION",
  SOFTWARE_ARCHITECTURE = "SOFTWARE_ARCHITECTURE",
  UI_DESIGN = "UI_DESIGN",
  USABILITY_TESTS = "USABILITY_TESTS",
  UX_DESIGN = "UX_DESIGN",
}
