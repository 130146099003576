export enum TechnologyType {
  CLEAN_CODE_DEV = "CLEAN_CODE_DEV",
  COMMUNICATION_FRAMEWORKS = "COMMUNICATION_FRAMEWORKS",
  DATABASES = "DATABASES",
  DEV_OPS = "DEV_OPS",
  FRAMEWORKS_CONCEPTS = "FRAMEWORKS_CONCEPTS",
  OTHERS = "OTHERS",
  PROGRAMMING_LANGUAGES = "PROGRAMMING_LANGUAGES",
  PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
  TESTING = "TESTING",
  TOOLS = "TOOLS",
  UI_UX_DESIGN = "UI_UX_DESIGN",
}
